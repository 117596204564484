import React from 'react';
import './ImageTitleChildren.scss';
/**
 * @namespace ImageTitleChildren
 * @function ImageTitleChildren
 * @author Trevor Cash
 * @since 11/13/21
 * @version 1.0.0
 * @component
 */
export default function ImageTitleChildren({
  image,
  alt,
  title,
  children,
  index,
  hotspot,
}) {
  return (
    <div
      className={`ImageTitleChildren-Container ${index % 2 ? 'odd' : 'even'}`}
    >
      {image ? (
        <img
          className='Image'
          src={image}
          alt={alt}
          style={{
            objectPosition: `${hotspot?.x * 100}% ${hotspot?.y * 100}%`,
          }}
          loading='lazy'
        />
      ) : (
        <div className='Image'></div>
      )}
      <div className='Text'>
        <h2>{title}</h2>
        {children}
      </div>
    </div>
  );
}

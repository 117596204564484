import React from 'react';
import './Worship.scss';

import Layout from '../../Components/Layout/Layout';
import ImageTitleChildren from '../../Components/ImageTitleChildren/ImageTitleChildren';

import { graphql, useStaticQuery } from 'gatsby';
import { PortableText } from '@portabletext/react';
/**
 * @namespace Worship
 * @function Worship
 * @author Trevor Cash
 * @since 11/13/21
 * @version 1.0.0
 * @component
 */
export default function Worship() {
  const data = useStaticQuery(graphql`
    query worshipQuery {
      allSanityWorship {
        nodes {
          _rawBody
        }
      }
      allSanityService {
        nodes {
          worship
          title
          study
          start
          end
          image {
            asset {
              url
            }
            hotspot {
              x
              y
            }
          }
        }
      }
    }
  `);
  const worship = data?.allSanityWorship?.nodes?.[0]?._rawBody;
  const services = data?.allSanityService?.nodes;

  return (
    <Layout
      className='Worship-Container'
      title='Worship'
      description='You are invited to join us for worship. At First Church in West Jefferson, Ohio we are a family committed to worship, teaching, learning and caring for one another and those in need.'
    >
      <h1>Worship</h1>
      {worship && <PortableText value={worship} />}
      {services?.map((item, index) => (
        <ImageTitleChildren
          key={index}
          image={item?.image?.asset?.url}
          hotspot={{ x: item?.image?.hotspot?.x, y: item?.image?.hotspot?.y }}
          alt='Sunday Morning Worship'
          title={item.title}
          index={index}
        >
          <p>
            {' '}
            {item.worship && 'Worship'}
            {item.worship && item.study && ' and '}
            {item.study && 'Study'}
            {item.worship || (item.study && ':')} {item.start && item.start}
            {item.start && item.end && ' - '}
            {item.end && item.end}
          </p>
        </ImageTitleChildren>
      ))}
    </Layout>
  );
}
